<template>
  <div class="services__list">
    <div
      class="services__list-item"
      :class="{ 'glow-animation': service.id === 5 || service.id === 6 }"
      v-for="service in servicesList"
      :key="service.id"
      @click="openDialog(service.id)"
    >
      <div class="services__list-item__top">
        <div class="services__list-item__top-number">0{{ service.id }}/</div>
        <div class="services__list-item__top-arrow">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="14"
            height="14"
            viewBox="0 0 14 14"
            fill="none"
          >
            <path
              d="M1.15619 12.1264C0.8633 12.4193 0.8633 12.8942 1.15619 13.1871C1.44909 13.48 1.92396 13.48 2.21685 13.1871L1.15619 12.1264ZM13.7502 1.34303C13.7502 0.928817 13.4144 0.59303 13.0002 0.59303L6.25023 0.59303C5.83602 0.59303 5.50023 0.928816 5.50023 1.34303C5.50023 1.75724 5.83602 2.09303 6.25023 2.09303L12.2502 2.09303L12.2502 8.09303C12.2502 8.50724 12.586 8.84303 13.0002 8.84303C13.4144 8.84303 13.7502 8.50724 13.7502 8.09303L13.7502 1.34303ZM2.21685 13.1871L13.5306 1.87336L12.4699 0.8127L1.15619 12.1264L2.21685 13.1871Z"
              fill="#FEA31E"
            />
          </svg>
        </div>
      </div>
      <div class="services__inner">
        <div class="services__inner-title">
          {{ "services_list_title_" + service.id | localize }}
        </div>
        <div class="services__inner-desc">
          {{ "services_list_desc_" + service.id | localize }}
        </div>
        <div class="services__inner-dark-title">
          {{ "services_list_title_dark_" + service.id | localize }}
        </div>
      </div>
    </div>

    <!-- Використання окремого компонента для діалогового вікна -->
    <ServiceDialog
      :dialog.sync="dialog"
      :dialogTitle="dialogTitle"
      :dialogContent="dialogContent"
      @close-dialog="closeDialog"
    />
  </div>
</template>

<script>
import ServiceDialog from "@/components/Services/ServiceDialog.vue"; // Імпорт компонента

export default {
  components: {
    ServiceDialog, // Реєстрація компонента
  },
  data() {
    return {
      servicesList: [
        { id: 1 },
        { id: 2 },
        { id: 3 },
        { id: 4 },
        { id: 5 },
        { id: 6 },
      ],
      dialog: false,
      dialogTitle: "",
      dialogContent: [],
    };
  },
  methods: {
    openDialog(id) {
      if (id === 5 || id === 6) {
        this.dialogTitle = this.$options.filters.localize(
          "services_list_title_" + id
        );
        this.dialogContent = this.getDialogContent(id);
        this.dialog = true;
      }
    },
    getDialogContent(id) {
      const content = [];
      if (id === 5) {
        for (let i = 1; i <= 10; i++) {
          content.push({
            title: this.$options.filters.localize(
              "list_title_item_internet_marketing_" + i
            ),
            text: this.$options.filters.localize(
              "list_title_item_internet_marketing_text_" + i
            ),
          });
        }
      } else if (id === 6) {
        for (let i = 1; i <= 6; i++) {
          content.push({
            title: this.$options.filters.localize(
              "list_title_item_design_" + i
            ),
            text: this.$options.filters.localize(
              "list_title_item_design_text_" + i
            ),
          });
        }
      }
      return content;
    },
    closeDialog() {
      this.dialog = false;
    },
  },
};
</script>

<style scoped>
.services__list-item {
  cursor: pointer;
  border-radius: 10px;
  border: 2px solid #fff;
  max-width: 620px;
  width: 100%;
  min-height: 290px;
  margin-bottom: 64px;
  transition: all 0.5s;
  background: #0d0d0d;
}
.services__list-item__top {
  border-bottom: 2px solid #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.services__list-item__top-number {
  color: #c7c7c7;
  font-size: 16px;
  font-weight: 400;
  line-height: 18px;
  padding: 5px 32px;
  transition: color 0.5s;
}
.services__list-item__top-arrow {
  padding: 0 32px;
  border-left: 2px solid #fff;
  padding: 5px 32px;
}
.services__inner {
  padding: 32px;
}
.services__inner-title {
  color: #fea31e;
  font-size: 36px;
  font-weight: 400;
  line-height: 40px;
  margin-bottom: 12px;
}
.services__inner-desc {
  margin-bottom: 32px;
  color: #c7c7c7;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
}
.services__inner-dark-title {
  color: rgb(255, 255, 255);
  font-size: 48px;
  font-weight: 600;
  line-height: 52px;
  text-transform: uppercase;
  transition: color 0.5s;
  opacity: 0.1;
}

/* Анімація для блоків з id 5 і 6 */
.glow-animation {
  animation: glow 2s infinite alternate;
}

@keyframes glow {
  0% {
    box-shadow: 4px 4px 25px 4px rgba(254, 163, 30, 0.4);
  }
  500% {
    box-shadow: 4px 4px 25px 4px rgba(254, 163, 30, 0.8);
  }
}

.services__list-item:hover .services__list-item__top-number {
  color: #fea31e !important;
}
.services__list-item:hover {
  box-shadow: 4px 4px 25px 4px rgba(254, 163, 30, 0.4);
}
.services__list-item:hover .services__inner-dark-title {
  color: #fea31e !important;
}

@media (max-width: 768px) {
  .services__list-item {
    max-width: none;
    margin-bottom: 32px;
  }
}
@media (max-width: 609px) {
  .services__inner-title {
    font-size: 28px;
    line-height: 32px;
    font-weight: 400;
  }
  .services__inner-desc {
    font-size: 14px;
    font-weight: 400;
  }
  .services__inner-dark-title {
    font-size: 36px;
    line-height: 40px;
  }
}
@media (max-width: 375px) {
  .services__inner {
    padding: 9px;
  }
}
</style>