<template>
    <v-dialog :value="dialog" max-width="800" @input="onInput">
      <v-card class="dialog-card" color="#0D0D0D">
        <!-- Заголовок і кнопка "Закрити" в одному рядку -->
        <div class="dialog-header">
          <v-card-title class="dialog-title">
            {{ dialogTitle }}
          </v-card-title>
          <button class="dialog-close-btn" @click="closeDialog">
            <img src="@/assets/img/close.svg" alt="Закрити" />
          </button>
        </div>
  
        <!-- Контент діалогового вікна -->
        <v-card-text class="dialog-content">
          <div v-for="(item, index) in dialogContent" :key="index" class="dialog-item">
            <p class="dialog-item-title">{{ item.title }} <span class="dialog-item-text">{{ item.text }}</span></p>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </template>
  
  <script>
  export default {
    props: {
      dialog: {
        type: Boolean,
        required: true,
      },
      dialogTitle: {
        type: String,
        required: true,
      },
      dialogContent: {
        type: Array,
        required: true,
      },
    },
    methods: {
      closeDialog() {
        this.$emit("close-dialog");
      },
      onInput(value) {
        this.$emit("update:dialog", value);
      },
    },
  };
  </script>
  
  <style scoped>
  .dialog-card {
    border-radius: 10px;
    border: 2px solid var(--W, #fff);
    background: #0d0d0d;
    box-shadow: 4px 4px 14px 1px #FEA31E !important;
    color: #fff;
    max-height: 80vh; /* Обмеження висоти діалогового вікна */
    display: flex;
    flex-direction: column;
  }
  
  /* Заголовок і кнопка "Закрити" в одному рядку */
  .dialog-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
  }
  
  .dialog-title {
    color: var(--Y, #fea31e);
    font-size: 36px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 0; /* Видаляємо стандартний margin */
  }
  
  .dialog-content {
    padding: 20px;
    overflow-y: auto; /* Дозволяє скролл, якщо контенту багато */
    flex-grow: 1; /* Контент займає доступний простір */
  }
  
  .dialog-item {
    margin-bottom: 20px;
  }
  
  .dialog-item-title {
    color: #fea31e;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px; /* 187.5% */
  }
  
  .dialog-item-text {
    color: #c7c7c7;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
  }
  
  /* Стилі для кнопки "Закрити" з іконкою */
  .dialog-close-btn {
    background: none;
    border: none;
    cursor: pointer;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .dialog-close-btn img {
    width: 24px; /* Розмір іконки */
    height: 24px;
  }
  
  .dialog-close-btn:hover img {
    filter: brightness(0.8); /* Зміна яскравості при наведенні */
  }
  
  /* Приховуємо скролер */
  .dialog-content::-webkit-scrollbar {
    width: 0; /* Ширина скролеру для WebKit (Chrome, Safari) */
  }
  
  .dialog-content {
    scrollbar-width: none; /* Для Firefox */
    -ms-overflow-style: none; /* Для IE/Edge */
  }
  </style>